@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.experiencedetailsside {
    &__title {
        font-weight: 600;
        color: $title-white;
    }

    &__date {
        @include font-size-height-weight(1.6rem, 2.4rem, 400);

        @include desktop {
            @include font-size-height-weight(1.8rem, 2.6rem, 400);
        }
    }

    &__li {
        @include font-size-height-weight(1.6rem, 2.4rem, 400);
        list-style-type: none;
        margin: 1.6rem 0;

        @include desktop {
            @include font-size-height-weight(1.8rem, 2.6rem, 400);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-tablet {
        display: none;

        @include tablet {
            width: 65%;
            display: initial;
        }
    }
}