@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.contact {
    @include flex(center, column);
    align-items: center;
    box-sizing: border-box;

    &__form {
        @include flex(center, column);
        box-sizing: border-box;
        width: 100%;

        @include tablet {
            width: 70%;
        }

        &--tablet {
            @include tablet {
                @include flex(null, row);

            }
        }

        &-fields {
            @extend .form-fields;

            &::-webkit-input-placeholder {
                color: $text-white;
            }

            &--select {
                color: $text-white;

                & option {
                    color: $title-white;
                    background-color: #0A192F;
                }
            }

            &--tablet-left {
                @include tablet {
                    margin-right: .4rem;
                }

                @include desktop {
                    margin-right: .8rem;
                }
            }

            &--tablet-right {
                @include tablet {
                    margin-left: .4rem;
                }

                @include desktop {
                    margin-left: .8rem;
                }
            }

            &--error {
                box-shadow: 0 0 5px 2px $danger;
                // border: 1px solid red;
            }
        }

        &-validation-message {
            text-align: center;
            color: $danger;
            margin: .8rem 0;
        }
    }

    &__button {
        @include tablet {
            @include flex(center, null);
        }


    }
}