@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.contact__textarea {
    box-sizing: border-box;
    padding-top: 1rem;
    min-height: 10rem;

    @include desktop {
        min-height: 15rem;
        padding: 1.6rem;
    }
}