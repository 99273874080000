@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.experience {
    @include flex(space-between, row);
    padding: .8rem 0;

    &__title {
        line-height: 2.5rem;
        vertical-align: middle;
    }
}