@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.logo__image {
    width: 10rem;
    cursor: pointer;

    @include tablet {
        width: 15rem;
    }

    @include desktop {
        width: 20rem;
    }
}