@use "../../../styles/global" as *;
@use "../../../styles/partials/mixins" as *;

.carousel__slide {
    @include width-height(100%, null);
    @include flex(space-between, column);
    flex-grow: 1;
    align-items: center;
    box-sizing: border-box;

    @include tablet {
        @include flex(center, row);
    }

    @include desktop {
        justify-content: space-around;
    }

    &-image {
        @include flex(center, null);
        max-height: 26.5rem;
        align-items: center;
        box-sizing: border-box;

        @include tablet {
            margin-right: 2.4rem;
            width: 50%;
        }

        @include desktop {
            justify-content: left;
            min-width: 40rem;
            max-height: 30rem;
        }

        .laptop {
            width: 100%;
            max-height: 25rem;

            @include tablet {
                max-width: 40rem;
            }

            @include desktop {
                max-width: 70rem;
                max-height: 50rem;
            }
        }
    }

    &-description {
        @include flex(space-between, column);
        flex-grow: 1;
        align-items: center;

        @include tablet {
            width: 50%;
        }

        @include desktop {
            height: 30rem;
        }

        &-title {
            margin: 1.6rem 0;
        }

        &-paragraph {
            margin-bottom: 1.6rem;
            text-align: center;
        }
    }
}