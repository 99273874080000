@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;



.mediacarousel {
    width: 100%;
    @include flex(center, column);
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    background-color: rgba($color: $lightest-navy, $alpha: 0.7);
    @include box-shadow($primary-gold);
    border-radius: 4px;
    padding: 1.6rem;
    margin: auto;
    margin-bottom: 2.4rem;

    @include tablet {
        width: 90%;
        padding: 2.4rem;
    }

    @include desktop {
        width: 80%;
        padding: 3.2rem;
    }

    @include desktop-large {
        width: 70%;
        padding: 3.2rem;
    }

    &__container {
        width: 100%;
        box-sizing: border-box;
        @include flex(center, row);
        align-items: center;

        @include desktop {
            justify-content: space-between;
        }
    }

    &__description {
        margin: .8rem 0;

        @include tablet {
            margin: 1.6rem 0;
        }
    }

    &__buttons {
        height: 2rem;

        @include tablet {
            height: 3rem;
        }

        @include desktop {
            display: none;
        }
    }

    &__previous,
    &__next {
        box-sizing: border-box;
        border: none;
        background-color: transparent;
        @include width-height(2rem, 2rem);
        cursor: pointer;
        margin: 0 1.6rem;

        @include tablet {
            @include width-height(3rem, 3rem);
        }
    }

    &__previous-image,
    &__next-image {
        width: 2rem;

        @include tablet {
            width: 3rem;
        }
    }
}