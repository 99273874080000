@use "../../../styles/global" as *;
@use "../../../styles/partials/mixins" as *;

.sidebar-nav-item {
    @include font-size-height-weight(2.1rem, 2.4rem, 400);
    text-decoration: none;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: $primary-gold;
    height: 4rem;
}