@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.footer {
    @include flex(center, column);
    @extend .sections--margins;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 2.4rem 0;
    border-top: 1px solid $primary-gold;
    position: relative;

    @include tablet {
        padding-top: 3.2rem;
    }

    @include desktop {
        padding-top: 4rem;
    }

    &__back-to-top {
        @include width-height(3.2rem, 3.2rem);
        position: absolute;
        top: -1.6rem;
        background-color: $navy;
        padding: 0 .4rem;

        @include tablet {
            @include width-height(4rem, 4rem);
            top: -2rem;
        }

        @include desktop {
            @include width-height(4.8rem, 4.8rem);
            top: -2.4rem;
        }

        &-image {
            @include width-height(3.2rem, 3.2rem);

            @include tablet {
                @include width-height(4rem, 4rem);
            }

            @include desktop {
                @include width-height(4.8rem, 4.8rem);
            }
        }
    }

    &__header {
        margin-bottom: .8rem;
    }

    &__comment {
        @include font-size-height-weight(1.4rem, 2rem, 400);
        text-align: center;
        margin-top: .8rem;
    }

    &__footerauthorsocials {
        width: 100%;

        @include tablet {
            @include flex(null, row);
        }
    }
}