@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.mediacarouselslide {

    &__image {
        aspect-ratio: 16/9;
        width: 100%;
    }

    &__previous,
    &__next {
        display: none;

        @include desktop {
            display: initial;
            box-sizing: border-box;
            border: none;
            background-color: transparent;
            @include width-height(4rem, 4rem);
            cursor: pointer;
            margin: 0 1.6rem;
        }
    }

    &__previous-image,
    &__next-image {
        width: 4rem;
    }
}