@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;



.githublink {
    align-self: center;
    @include width-height(100%, 4rem);
    max-width: 40rem;
    text-decoration: none;

    &__button {
        @include flex(center, row);
        align-items: center;
        align-self: center;
        @include width-height(100%, 4rem);
        max-width: 40rem;
        box-sizing: border-box;
        border: none;
        border-radius: 4px;
        outline: none;
        padding: .8rem 1.6rem;
        cursor: pointer;
        background-color: transparent;
        border: 1px solid $primary-gold;
        color: $primary-gold;
        position: relative;
    }

    &__icon {
        width: 2rem;
        margin-right: 1.6rem;
    }

    &__text {
        margin-top: .6rem;
        color: $primary-gold;
        transition: 400ms;
    }

    &__text-hover {
        margin-top: .6rem;
        color: $navy;
        transition: 400ms;
    }
}