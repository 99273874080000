@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.filterby {
    @include flex(space-between, row);
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $navy;
    padding: 1.6rem;
    z-index: 2;
    border-top: 2px solid $primary-gold;
    cursor: pointer;

    @include tablet {
        padding: 2.4rem 3.2rem;
    }

    @include desktop {
        padding: 2.4rem 8rem;
    }

    &__text {
        color: $primary-gold;
        line-height: 2.4rem;

        @include desktop {
            line-height: 3.2rem;
        }
    }

    &__button {
        @extend .filterby__button--width-height;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &-image {
            @extend .filterby__button--width-height;
        }
    }
}

