@font-face {
    font-family: 'Noto';
    src: url(../../assets/fonts/NotoSerifTelugu-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Noto';
    src: url(../../assets/fonts/NotoSerifTelugu-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Noto';
    src: url(../../assets/fonts/NotoSerifTelugu-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Noto';
    src: url(../../assets/fonts/NotoSerifTelugu-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Noto';
    src: url(../../assets/fonts/NotoSerifTelugu-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Noto';
    src: url(../../assets/fonts/NotoSerifTelugu-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Noto';
    src: url(../../assets/fonts/NotoSerifTelugu-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Noto';
    src: url(../../assets/fonts/NotoSerifTelugu-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}


@font-face {
    font-family: 'Noto';
    src: url(../../assets/fonts/NotoSerifTelugu-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}