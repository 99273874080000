@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.fbc {
    @include flex(center, column);
    align-items: flex-end;
    position: fixed;
    left: 0;
    bottom: -100%;
    width: 100%;
    background-color: $navy;
    box-sizing: border-box;
    padding: 1.6rem;
    z-index: 1;
    transition: 500ms;

    &__title {
        box-sizing: border-box;
    }

    &__select-author {
        @extend .form-fields;

        &::-webkit-input-placeholder {
            color: $title-white;
        }

        & option {
            color: $title-white;
            background-color: $navy;
        }
    }
}

.fbc.active {
    bottom: 0;
}