@use "../../styles/global" as *;
@use "../../styles/partials/mixins" as *;

.navbar {
    display: none;

    @include desktop {
        @include flex(null, row);
    }
}

.navbar-item-header {
    @include font-size-height-weight(2.1rem, 2.1rem, 400);
    text-decoration: none;
    cursor: pointer;
    box-sizing: border-box;
    vertical-align: middle;
    margin: auto;
    height: 4rem;
    position: relative;
    display: flex;
    align-items: center;
    color: $primary-gold;
    margin-left: 1.6rem;
    margin-bottom: .8rem;
    padding: .6rem .8rem 0 .8rem;

    @include desktop {
        @include font-size-height-weight(2.7rem, 2.7rem, 400);
        height: 4.5rem;
        padding: .8rem .8rem 0 .8rem;
    }
}

.from-top {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    background-color: $primary-gold;
    width: 100%;

    transform: scaleX(0);
    transform-origin: left;
}

.navbar-item-header:hover .from-top {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 250ms;
    transition-delay: 0ms;
}

.from-right {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background-color: $primary-gold;
    width: 1px;

    transform: scaleY(0);
    transform-origin: top;
}

.navbar-item-header:hover .from-right {
    transform: scaleY(1);
    transition: transform 250ms;
    transition-delay: 249ms;
}

.from-bottom {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: $primary-gold;
    width: 100%;

    transform: scaleX(0);
    transform-origin: right;
}

.navbar-item-header:hover .from-bottom {
    transform: scaleX(1);
    transition: transform 250ms;
    transition-delay: 498ms;
}

.from-left {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    background-color: $primary-gold;
    width: 1px;

    transform: scaleY(0);
    transform-origin: bottom;
}

.navbar-item-header:hover .from-left {
    transform: scaleY(1);
    transition: transform 250ms;
    transition-delay: 749ms;
}