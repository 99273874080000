@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.project-card {
    @include flex(center, column);
    align-items: center;
    box-sizing: border-box;
    @include width-height(100%, null);
    border-bottom: 1px solid $primary-gold;
    margin-bottom: 1.6rem;

    @include tablet {
        @include flex(center, row);
        padding-bottom: 2.4rem;
        margin-bottom: 2.4rem;
    }

    @include desktop {
        @include flex(space-around, row);
        padding-bottom: 3.2rem;
        margin-bottom: 3.2rem;
    }

    &__image {
        @include flex(center, null);
        max-height: 26.5rem;
        align-items: center;
        box-sizing: border-box;

        @include tablet {
            margin-right: 2.4rem;
            width: 50%;
        }

        @include desktop {
            min-width: 40rem;
            max-height: 30rem;
        }

        &-img {
            width: 100%;
            max-height: 25rem;

            @include tablet {
                max-width: 40rem;
            }

            @include desktop {
                max-width: 50rem;
                max-height: 40rem;
            }
        }
    }

    &__description {
        @include flex(space-between, column);
        align-items: center;
        color: $primary-black;

        @include tablet {
            width: 50%;
        }

        @include desktop {
            height: 30rem;
        }

        &-title {
            margin: 1.6rem 0;
            color: $title-white;
        }

        &-paragraph {
            margin-bottom: 1.6rem;
            text-align: center;
            color: $text-white;
        }
    }
}