@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.header {
    padding-top: 1.6rem;
    box-sizing: border-box;

    @include tablet {
        padding-top: 3.2rem;
    }

    &__content {
        @include flex(space-between, row);
        text-align: center;
        border-bottom: 1px solid $primary-gold;
    }
}

.placeholder {
    @include width-height(2rem, 2rem);

    @include desktop {
        display: none;
    }
}