@use "../../styles/global" as *;
@use "../../styles/partials/mixins" as *;

.author {
    @include flex(center, column);
    @extend .page-padding;

    &__linkto {
        @include flex(center, null);
        text-decoration: none;
    }
}

.experience-section--tablet {
    @include tablet {
        @include flex(space-between, row);
        width: 100%;
    }
}

.experience-companies {
    width: 100%;

    @include tablet {
        width: 30%;
    }
}