.App {
  /* background-image: url(./assets/images/shiny-overlay.svg); */
  background-color: #0A192F;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  box-sizing: border-box;
}