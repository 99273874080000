@use "../../styles/global" as *;
@use "../../styles/partials/mixins" as *;

.banner {
    @include flex(center, null);
    width: 100%;
    box-sizing: border-box;

    &__image {
        width: 100%;
    }
}