@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.about-author {
    @include flex(center, column);
    align-items: center;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 1.6rem;
    border-radius: 4px;
    background-color: $background-blue;
    margin-bottom: .8rem;

    &:first-child {
        @include tablet {
            margin-right: .8rem;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    @include tablet {
        margin-bottom: 0;
    }

    @include desktop {
        @include flex(center, row);
        padding: 3.2rem;
    }

    &:hover,
    &:focus {
        transition: 600ms;
        box-shadow: 0 0 5px 1px $primary-gold;
    }

    &__profile {
        @include flex(center, column);
        align-items: center;

        @include desktop {
            @include flex(space-between, column);
            width: 50%;
            height: 100%;
        }
    }

    &__name {
        color: $title-white;
        margin: 1.6rem 0 .8rem 0;
    }

    &__title {
        color: $text-white;
    }

    &__right-container {
        width: 100%;

        @include desktop {
            @include flex(space-between, column);
            width: 50%;
            height: 100%;
        }
    }

    &__techstack {
        margin: .8rem 0;

        @include desktop {
            margin: 0;
            margin-bottom: .8rem;
        }
    }
}

.author-image {
    background-image: radial-gradient(circle, rgb(33, 33, 168), rgb(27, 27, 94), $navy);
}