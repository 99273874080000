@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.hero {
    @extend .sections--margins;
    box-sizing: border-box;
    @include flex(center, column);
    align-items: center;
    margin-bottom: 1.6rem;

    @include desktop {
        @include flex(space-around, row);
    }

    &__presentation {
        text-align: center;

        &-name {
            @include font-size-height-weight(4rem, 4.8rem, 800);
            color: $primary-gold;

            @include tablet {
                @include font-size-height-weight(4.8rem, 5.6rem, 800);
            }

            @include desktop {
                @include font-size-height-weight(5.6rem, 6.4rem, 800);
                margin: 2.4rem 0;
            }
        }
    }
}