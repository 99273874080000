@use "../../styles/global" as *;
@use "../../styles/partials/mixins" as *;

.projectsummary {
    @include flex(center, column);

    &__text {
        margin-bottom: 1.6rem;

        @include tablet {
            margin-bottom: 2.4rem;
        }

        @include desktop {
            margin-bottom: 3.2rem;
        }
    }
}