@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.footerauthorsocials {
    @include flex(center, row);
    align-items: center;
    width: 100%;
    padding: .8rem 0;

    &__author-name {
        margin: auto 1.6rem auto 0;
        color: $title-white;
    }

    &__image {
        width: 3rem;
        margin: 0 .8rem;
    }
}

.footer__socials {
    @include flex(space-around, row);
    width: 50%;

    @include tablet {
        width: 40%;
    }

    @include desktop {
        width: 30%;
    }

    &-image {
        width: 2rem;

        @include desktop {
            width: 3rem;
        }
    }

}