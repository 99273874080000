@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.close-button {
    border: none;
    background-color: transparent;
    @include width-height(2rem, 2rem);
    cursor: pointer;

    &__image {
        width: 2rem;
    }
}