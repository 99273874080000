@use "../../styles/global" as *;
@use "../../styles/partials/mixins" as *;

.individualproject {
    @extend .page-padding;
    @include flex(center, column);

    &__section-title {
        @include font-size-height-weight(2.4rem, 2.4rem, 600);

        @include tablet {
            @include font-size-height-weight(4rem, 4rem, 600);
        }

        @include desktop {
            @include font-size-height-weight(4.8rem, 4.8rem, 600);
        }
    }
}

.video-responsive {
    @include flex(center, null);
    @include width-height(100%, 0);
    align-self: center;
    box-sizing: border-box;
    aspect-ratio: 16/9;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    border-radius: 4px;
    @include box-shadow($primary-gold);

    @include tablet {
        width: 90%;
        padding-bottom: 50.25%;
    }

    @include desktop {
        width: 80%;
        padding-bottom: 44.95%;
    }

    @include desktop-large {
        width: 70%;
        padding-bottom: 39.35%;
    }
}

.video-responsive iframe {
    align-self: center;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    aspect-ratio: 16/9;
    width: 100%;
    position: absolute;
}