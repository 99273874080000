@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.button {
    @include width-height(100%, 4rem);
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    outline: none;
    padding: .8rem 1.6rem;
    margin: .4rem 0;
    cursor: pointer;
    position: relative;
}

.button__modal {
    @include width-height(12rem, 2.5rem);
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    margin: .5rem;
}

.button-transparent {
    background-color: transparent;
    border: 1px solid $primary-gold;
    color: $primary-gold;
    transition: color 500ms ease-in-out;
    z-index: 1;

    @include desktop {
        margin: 0;
    }

    &:hover {
        color: $lightest-navy;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        background-color: $primary-gold;

        transform: scaleX(0);
        transition: transform 500ms ease-in-out;
    }

    &:hover::before {
        transform: scaleX(1);
    }
}

.contact__button--tablet {
    background-color: transparent;
    border: 1px solid $primary-gold;
    color: $primary-gold;
    transition: color 500ms ease-in-out;
    z-index: 1;

    &:hover {
        color: $lightest-navy;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        background-color: $primary-gold;

        transform: scaleX(0);
        transition: transform 500ms ease-in-out;
    }

    &:hover::before {
        transform: scaleX(1);
    }

    @include tablet {
        max-width: 40rem;
    }

    @include desktop {
        margin: .8rem 0;
    }
}

.featured__button {
    margin-top: 2.4rem;

    @include tablet {
        max-width: 40rem;
    }
}

.button-carousel {
    max-width: 40rem;
    margin: 1.6rem 0;

    @include tablet {
        margin-bottom: 0;
    }
}

.button--max-width {
    align-self: center;
    max-width: 40rem;
}

.hero__button {
    align-self: center;
    max-width: 40rem;

    @include desktop {
        margin-top: 3.2rem;
    }
}

.button-gold {
    align-self: center;
    background-color: $primary-gold;
    color: $navy;

    transition: all .3s ease-in-out;

    @include tablet {
        max-width: 40rem;
    }

    &:hover,
    &:focus {
        transform: scale(1.02);
        font-weight: 600;

        @include tablet {
            transform: scale(1.1);
        }
    }
}