@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.socialside {
    display: none;

    @include desktop {
        @include flex(null, column);
        align-items: center;
        position: fixed;
        right: 2.5rem;
        bottom: 0;
    }

    &__socials {
        @include flex(null, column);

        &-link {
            margin-bottom: 2.4rem;
        }
    }
}