@use "../../styles/global" as *;
@use "../../styles/partials/mixins" as *;

.projects {
    @extend .page-padding;
    background-color: $navy;

    &__placeholder {
        height: 5.8rem;

        @include tablet {
            height: 7.4rem;
        }

        @include desktop {
            height: 8.2rem;
        }

    }

    &__filter--desktop {
        display: none;

        @include desktop {
            @include flex(null, row);
            padding-bottom: 3.2rem;
            margin-bottom: 3.2rem;
            border-bottom: 1px solid $primary-gold;
        }
    }

    &__select-author {
        @extend .form-fields;
        margin-right: 1.6rem;

        &::-webkit-input-placeholder {
            color: $title-white;
        }

        & option {
            color: $title-white;
            background-color: $navy;
        }
    }

    &__input-author {
        margin-right: 0;
    }
}

.projects__placeholder.active {
    margin-bottom: 14rem;

    @include desktop {
        margin-bottom: 19rem;
    }
}