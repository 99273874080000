@use "../../../styles/global" as *;
@use "../../../styles/partials/mixins" as *;

.header__menu {
    @include width-height(2rem, 2rem);
    margin-top: auto;
    margin-bottom: auto;

    @include desktop {
        display: none;
    }

    &-button {
        width: 2rem;
        height: 2rem;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &-img {
            width: 2rem;
        }
    }
}