@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.about {
    @include flex(center, column);
    align-items: center;

    &__authors {
        width: 100%;

        @include tablet {
            @include flex(center, row);
        }
    }
}