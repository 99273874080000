@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.section-title {
    @extend .sections--margins;
    @include flex(center, row);
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    margin-bottom: 1.6rem;

    @include tablet {
        margin-bottom: 2.4rem;
    }

    @include desktop {
        margin-bottom: 3.2rem;
    }

    &__span {
        background-color: $primary-gold;
        height: 2px;
        flex-grow: 1;
    }

    &__div {
        display: inline-block;
    }

    &__title {
        color: $primary-gold;
        margin: .4rem .4rem 0 .4rem;
    }
}