@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.carousel {
    @include width-height(100%, 60rem);
    @include flex(space-between, column);
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    background-color: rgba($color: $lightest-navy, $alpha: 0.7);
    @include box-shadow($primary-gold);
    border-radius: 4px;
    padding: 1.6rem;

    @include tablet {
        @include width-height(100%, 50rem);
        padding: 2.4rem;
    }

    @include desktop {
        padding: 3.2rem;
    }

    &__button {
        @include tablet {
            margin-top: 1.6rem;
        }
    }

    &__previous,
    &__next {
        box-sizing: border-box;
        border: none;
        background-color: transparent;
        @include width-height(2rem, 2rem);
        cursor: pointer;
        margin: 0 1.6rem;

        @include tablet {
            @include width-height(3rem, 3rem);
        }
    }

    &__previous-image,
    &__next-image {
        width: 2rem;

        @include tablet {
            width: 3rem;
        }
    }
}