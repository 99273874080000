@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.ac__tsi {
    @include flex(center, column);
    align-items: center;
    @include width-height(6rem, 6rem);
    border: 1px solid $text-white;
    box-sizing: border-box;
    padding: .4rem;
    margin: .4rem;
    border-radius: 10px 0;

    @include tablet {
        margin: .4rem;
    }

    &__img-container {
        &__img {
            width: 3rem;
        }
    }

    &__name {
    @include font-size-height-weight(1.2rem, 1.2rem, 400);
    }
}