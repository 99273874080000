@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.experiencedetails {
    @include flex(null, column);
    max-height: 0;
    overflow: hidden;
    transition: 1s;

    @include tablet {
        display: none;
    }
}

.experiencedetails.active {
    @include flex(null, column);
    width: 100%;
    height: auto;
    max-height: 50rem;
    transition: 1s;
    box-sizing: border-box;

    @include tablet {
        display: none;
    }
}

.experiencedetails {
    &__title {
        font-weight: 600;
        color: $title-white;
    }

    &__li {
        list-style-type: none;
        margin: 1.6rem 0;
    }
}