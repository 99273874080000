@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

.sidebar {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    padding: 1.6rem;
    background-color: $navy;
    z-index: 3;
    transition: 850ms;

    @include tablet {
        width: 50%;
    }

    &__content {
        width: 100%;
        height: 100%;
    }

    &__header {
        @include flex(space-between, row);
        align-items: center;
        border-bottom: 1px solid $primary-gold;
    }
}

.sidebar.active {
    left: 0;
}