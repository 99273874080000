@use "../../styles/global" as *;
@use "../../styles/partials/mixins" as *;

.projectfeatures {
    margin-bottom: 1.6rem;

    @include tablet {
        margin-bottom: 2.4rem;
    }

    @include desktop {
        margin-bottom: 4rem;
    }

    &__header {
        margin-bottom: 1.6rem;

        @include desktop {
            margin-bottom: 2.4rem;
        }
    }

    &__li {
        margin-left: 1.7rem;
        list-style-position: outside;
        margin-bottom: .8rem;

        @include tablet {
            margin-left: 1.8rem;
        }

        @include desktop {
            margin-left: 2rem;
        }

        &::marker {
            color: $primary-gold;
        }

        @include tablet {
            margin-bottom: 1.6rem;
        }

        @include desktop {
            margin-bottom: 2.4rem;
        }
    }
}