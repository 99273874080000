@use './partials/typography' as *;
@use './partials/mixins' as *;

$noto: 'Noto';

* {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    font-family: $noto, sans-serif;
}

$primary-black: #1A1A1A;
$primary-gold: #BE9E44;
$secondary-black: #565656;
$secondary-sand: #f6f1e4;
$secondary-yellow: #f8ba0a;
$navy: #0A192F;
$light-navy: #112240;
$lightest-navy: #233554;
$background-blue: rgba($color: $lightest-navy, $alpha: 0.7);
$aureolin: #F8F32B;
$rose: #FF007F;
$sky-blue: #57CBFF;
$title-white: #E6F1FF;
$text-white: #A8B2D1;
$danger: rgb(228, 14, 14);

h1 {
    @include font-size-height-weight(2.7rem, 3rem, 600);
    color: $title-white;
    margin: 0;

    @include tablet {
        @include font-size-height-weight(3.2rem, 3.5rem, 600);
    }

    @include desktop {
        @include font-size-height-weight(4rem, 4.2rem, 600);
    }
}

h2 {
    @include font-size-height-weight(2.1rem, 2.4rem, 600);
    color: $title-white;
    margin: 0;

    @include tablet {
        @include font-size-height-weight(2.4rem, 2.7rem, 600);
    }

    @include desktop {
        @include font-size-height-weight(3rem, 3.3rem, 600);
    }
}

h3 {
    @include font-size-height-weight(1.8rem, 2.1rem, 400);
    color: $title-white;
    margin: 0;

    @include tablet {
        @include font-size-height-weight(2.1rem, 2.4rem, 400);
    }

    @include desktop {
        @include font-size-height-weight(2.4rem, 2.7rem, 400);
    }
}

p {
    @include font-size-height-weight(1.6rem, 2.4rem, 400);
    color: $text-white;
    margin: 0;

    @include tablet {
        @include font-size-height-weight(1.8rem, 2.6rem, 400);
    }

    @include desktop {
        @include font-size-height-weight(2.1rem, 3rem, 400);
    }
}

input {
    @include font-size-height-weight(1.6rem, 2.4rem, 400);
    margin: 0;

    @include tablet {
        @include font-size-height-weight(1.8rem, 2.6rem, 400);
    }

    @include desktop {
        @include font-size-height-weight(2.1rem, 3rem, 400);
    }
}

a {
    @include font-size-height-weight(1.6rem, 2.4rem, 400);
    margin: 0;

    @include tablet {
        @include font-size-height-weight(1.8rem, 2.6rem, 400);
    }

    @include desktop {
        @include font-size-height-weight(2.1rem, 3rem, 400);
    }
}

div {
    @include font-size-height-weight(1.6rem, 2.4rem, 400);
    color: $text-white;
    margin: 0;

    @include tablet {
        @include font-size-height-weight(1.8rem, 2.6rem, 400);
    }

    @include desktop {
        @include font-size-height-weight(2.1rem, 3rem, 400);
    }
}

button {
    @include font-size-height-weight(1.6rem, 2.4rem, 400);
    margin: 0;

    @include tablet {
        @include font-size-height-weight(1.8rem, 2.6rem, 400);
    }

    @include desktop {
        @include font-size-height-weight(2.1rem, 3rem, 400);
    }
}

option {
    @include font-size-height-weight(1.2rem, 1.2rem, 400);

    @include tablet {
        @include font-size-height-weight(1.6rem, 1.6rem, 400);
    }

    @include desktop {
        @include font-size-height-weight(1.6rem, 1.6rem, 400);
    }
}

li {
    @include font-size-height-weight(1.6rem, 2.4rem, 400);
    color: $text-white;
    margin: 0;

    @include tablet {
        @include font-size-height-weight(1.8rem, 2.6rem, 400);
    }

    @include desktop {
        @include font-size-height-weight(2.1rem, 3rem, 400);
    }
}

.sections--margins {
    margin-top: 3.2rem;

    @include tablet {
        margin-top: 4rem;
    }

    @include desktop {
        margin-top: 6.4rem;
    }
}

.hidden {
    display: none;
}

.page-padding {
    padding: 0 1.6rem;
    box-sizing: border-box;
    height: 100%;
    min-height: 100vh;

    @include tablet {
        padding: 0 3.2rem;
    }

    @include desktop {
        padding: 0 8rem;
    }
}

.form-fields {
    @include font-size-height-weight(1.6rem, 1.6rem, 400);
    color: $title-white;
    background-color: $background-blue;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    padding: .8rem 1.6rem;
    margin: .4rem 0;
    box-shadow: 0 0 5px 2px rgba($color: $primary-black, $alpha: 0.2);
    outline: $primary-gold;

    @include desktop {
        @include font-size-height-weight(2.1rem, 3rem, 400);
        margin: .8rem 0;
        height: 4.8rem;
    }
}

.filterby__button--width-height {
    @include width-height(2rem, 2rem);

    @include tablet {
        @include width-height(2.4rem, 2.4rem);
    }

    @include desktop {
        @include width-height(3.2rem, 3.2rem);
    }
}