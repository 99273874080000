@use "../../styles/global.scss" as *;
@use "../../styles/partials/mixins" as *;

//this class refers to the image on the about section of home page
.author-image {
    @include width-height(15rem, 15rem);
    border-radius: 4px;
    overflow: hidden;

    &__img {
        @include width-height(15rem, 15rem);
    }
}

//this class refers to the image on the hero section of author page
.hero__author-image {
    @include flex(center, null);
    width: 100%;
    max-width: 40rem;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    background-image: radial-gradient(circle, rgb(35, 35, 151), rgb(28, 28, 75), $navy);
    margin-top: 1.6rem;

    &-img {
        width: 100%;
        max-width: 40rem;
    }
}